import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-web-security',
  templateUrl: './web-security.component.html',
  styleUrls: ['./web-security.component.css']
})
export class WebSecurityComponent implements OnInit {
  lang: any;
  constructor(private languageService: LanguageService) { }

  ngOnInit(): void {
    this.languageService.current_language.subscribe(
      async (language) => {
        this.lang = language;
      }
    );
  }

}
