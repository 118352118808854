import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { WhyUsComponent } from './components/why-us/why-us.component';
import { SolutionsComponent } from './components/solutions/solutions.component';
import { PartnersComponent } from './components/partners/partners.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { WebPerformanceComponent } from './components/web-performance/web-performance.component';
import { WebSecurityComponent } from './components/web-security/web-security.component';
import { ServicesComponent }from './components/services/services.component';
import { IndustryComponent } from './components/industry/industry.component';
import { LocationComponent } from './components/location/location.component';
import { PartnersPageComponent } from './components/partners-page/partners-page.component';


const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: ComponentsComponent },
    { path: 'why-us',     component: WhyUsComponent },
    { path: 'solutions',           component: SolutionsComponent },
    { path: 'partners-page',          component: PartnersPageComponent },
    { path: 'contact-us',      component: ContactUsComponent },
    { path: 'web-performance',      component: WebPerformanceComponent },
    { path: 'web-security', component: WebSecurityComponent},
    { path: 'services', component: ServicesComponent },
    { path: 'industry', component:IndustryComponent },
    { path: 'location', component:LocationComponent },
    { path: "**", redirectTo: 'home' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
