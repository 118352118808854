import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {LanguageService } from '../../language.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    lang: string;

    constructor(public location: Location, private element : ElementRef,  private http: HttpClient, public Router: Router, private languageService:LanguageService, private modalService: NgbModal) {
        this.sidebarVisible = false;
        // Router.events.subscribe((val) => {
        //     if (val instanceof NavigationEnd) {
        //       if (this.lang == "CN") $('.content-language').collapse('toggle');
        //     }
        //   });
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.languageService.current_language.subscribe(
            async (language) => {
              this.lang = language;
              // if (this.language == "CN") $('.content-language').collapse('toggle');
            }
          );
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        console.log(html);
        console.log(toggleButton, 'toggle');

        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

    changeLanguage(lang?) {
        if (this.lang == 'EN') lang = 'CN';
        else if (this.lang == 'CN') lang = 'EN';
        // sessionStorage.setItem('lang', lang);
        this.languageService.update_language(lang);
        // $('.content-language').collapse('toggle');
      }

      openScrollableContent(longContent) {
        this.modalService.open(longContent, { scrollable: true });
    
      }
    
      onSubmit(contactForm: NgForm) {
        let successMsg = this.lang == 'EN' ? 'Enquiry Sent':'发送成功';
        let failMsg = this.lang == 'EN' ? 'Enquiry Failed':'发送失败';
        if (contactForm.valid) {
          const value = contactForm.value;
          this.modalService.dismissAll();
          const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          let response = this.http.post('https://formspree.io/f/xpzkrbwg',
            { FirstName: value.firstname, LastName: value.lastname, Company: value.company, Email: value.email, Phone: value.phone, Message: value.messages },
            { 'headers': headers }).subscribe(
              response => {
                console.log('response', response);
                if (response['ok'] == true) {
                  Swal.fire({
                    icon: "success",
                    title: successMsg,
                    showConfirmButton: false,
                    timer: 1800
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: failMsg,
                    showConfirmButton: false,
                    timer: 1800
                  });
    
                }
              }
            );
    
        } else {
          console.log('form not valid');
          Swal.fire({
            icon: "warning",
            title: failMsg,
            showConfirmButton: false,
            timer: 1800
          });
        }
    
      }
}
