import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {
  lang: string;
  constructor(private languageService: LanguageService) { }

  ngOnInit(): void {
    AOS.init();
    this.languageService.current_language.subscribe(
      async (language) => {
        this.lang = language;
      }
    );

  }

}
