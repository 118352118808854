import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  lang:any;

  constructor(private languageService: LanguageService) { }

  ngOnInit(): void {
    this.languageService.current_language.subscribe(
      async (language) => {
        this.lang = language;
      }
    );
  }

}
