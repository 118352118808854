import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../language.service';
import { HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as AOS from 'aos';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styleUrls: ['./components.component.css']
})

export class ComponentsComponent implements OnInit {
    @HostListener('document:keypress', ['$event'])
    // @ViewChild('carousel-control-next') carousel_control_next: ElementRef;

    lang: string;
    page = 4;
    page1 = 5;
    focus;
    focus1;
    focus2;
    key;
    date: {year: number, month: number};
    model: NgbDateStruct;
    image1:string;image2:string;image3:string;image4:string;
    constructor( private renderer : Renderer2, private languageService: LanguageService, private modalService: NgbModal) { }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: {month: number}) {
        return date.month !== current.month;
    }

    ngOnInit() {
        this.image1 = "./assets/img/performance.jpg";
        this.image2 = "./assets/img/managed_services.jpg";
        this.image3 = "./assets/img/china.jpg";        
        this.image4 = "./assets/img/focus_industry.jpeg";

        
        this.languageService.current_language.subscribe(
            async (language) => {
              this.lang = language;
            }
          );
        AOS.init();
        let carousel_control_next  = document.getElementsByClassName('carousel-control-next');
        let input_group_focus = document.getElementsByClassName('form-control');
        let input_group = document.getElementsByClassName('input-group');
        for (let i = 0; i < input_group.length; i++) {
            input_group[i].children[0].addEventListener('focus', function (){
                input_group[i].classList.add('input-group-focus');
            });
            input_group[i].children[0].addEventListener('blur', function (){
                input_group[i].classList.remove('input-group-focus');
            });
        }
    }

    sumthg(){
        console.log('sumthg');
    }
    // handleKeyboardEvent(event: KeyboardEvent) { 
    //     this.key = event.key;
    //     console.log('key',this.key);
    //   }

}
