import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']
})
export class SolutionsComponent implements OnInit {
  lang:any;
  constructor(private languageService: LanguageService) {
  
   }

  ngOnInit(): void {
    this.languageService.current_language.subscribe(
      async (language) => {
        this.lang = language;
      }
    );
  }

}
