import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  canClick : boolean = false;
  lang: string;

  constructor(private modalService: NgbModal, private http: HttpClient, private languageService: LanguageService) { }

  ngOnInit(): void {
    this.languageService.current_language.subscribe(
      async (language) => {
        this.lang = language;
      }
    );
  }

  onSubmit(contactForm: NgForm) {
    let successMsg = this.lang == 'EN' ? 'Enquiry Sent':'发送成功';
    let failMsg = this.lang == 'EN' ? 'Enquiry Failed':'发送失败';
    if (contactForm.valid) {
      const value = contactForm.value;
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      contactForm.reset();
      this.canClick = !this.canClick;
      let response = this.http.post('https://formspree.io/f/xpzkrbwg',
        { FirstName: value.firstname, LastName:value.lastname, Company: value.company, Email: value.email, Phone: value.phone, Message: value.messages },
        { 'headers': headers }).subscribe(
          response => {
            console.log('response', response);
         
            if (response['ok'] == true) {
              Swal.fire({
                icon: "success",
                title: successMsg,
                showConfirmButton: false,
                timer: 1800
              });
            } else {
              Swal.fire({
                icon: "error",
                title: failMsg,
                showConfirmButton: false,
                timer: 1800
              });

            }
          }
        );
        this.canClick = !this.canClick;

    } else {
      console.log('form not valid');
      Swal.fire({
        icon: "warning",
        title: failMsg,
        showConfirmButton: false,
        timer: 1800
      });
    }

  }

}
