import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  private language = new BehaviorSubject("EN");
  current_language = this.language.asObservable();
  update_language(value) {
    this.language.next(value);
  }
}
