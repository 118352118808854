import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-web-performance',
  templateUrl: './web-performance.component.html',
  styleUrls: ['./web-performance.component.css']
})
export class WebPerformanceComponent implements OnInit {
  lang:any;
  constructor(private languageService: LanguageService) {
  
   }

  ngOnInit(): void {
    this.languageService.current_language.subscribe(
      async (language) => {
        this.lang = language;
      }
    );
  }

}
