import { Component, OnInit } from '@angular/core';
import {LanguageService } from '../../language.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    test : Date = new Date();
    lang: string;

    constructor(private languageService:LanguageService,private modalService: NgbModal) { }

    ngOnInit() {
        this.languageService.current_language.subscribe(
            async (language) => {
              this.lang = language;
              // if (this.language == "CN") $('.content-language').collapse('toggle');
            }
          );
    }

    showContent(country) {
      this.modalService.open(country, { scrollable: true });
    }
  
}
