import { AotSummaryResolver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  lang: string;
  constructor(private languageService: LanguageService) { }

  ngOnInit(): void {
    AOS.init();
    this.languageService.current_language.subscribe(
      async (language) => {
        this.lang = language;
      }
    );
  }

}
