import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, } from '@angular/core';
import * as AOS from 'aos';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.css']
})
export class WhyUsComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  lang: string;
  successfulProjects: number = 1;
  happyCustomers: number = 1;
  portfolios: number = 1;
  experiences: number = 1;

  constructor(private languageService: LanguageService) { }


  ngOnInit(): void {
    AOS.init();
    this.languageService.current_language.subscribe(
      async (language) => {
        this.lang = language;
      }
    );

    window.setInterval(() => {
      if (this.successfulProjects < 100) this.successfulProjects++;
      if (this.happyCustomers < 100) this.happyCustomers++;
    }, 40);

    window.setInterval(() => {
      if (this.portfolios < 50) this.portfolios++;
      if (this.experiences < 25) this.experiences++;
    }, 80);

    this.scrollToBottom();
  }

  // ngAfterViewChecked() {
  //   this.scrollToBottom();
  // }

  scrollToBottom(): void {
    try {
      window.scroll({
        top: this.getTopOffset(this.myScrollContainer.nativeElement.scrollHeight),
        left: 0,
        behavior: "smooth"
      });
        // this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

}
